import React from "react";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import {
  tokenInfoBg,
  whiteBg,
  yellowBg,
} from "../components/SmallComponents/Images";

function TokenInformation() {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  return (
    <Box mt={8}>
      <Container>
        <Box
          sx={{
            background: `url('${tokenInfoBg}')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            py: 8,
            px: { xs: 5, sm: 8 },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: "#ffffff",
              fontSize: { xs: "25px", sm: "30px", md: "40px" },
              fontFamily: "Russo One",
              mb: 4,
              textAlign: "center",
            }}
          >
            TOKEN INFORMATION
          </Typography>
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box
              data-aos={mobileMatches ? "fade-down" : "fade-right"}
              data-aos-delay="30"
              data-aos-duration="2000"
              sx={{
                flex: 1,
                textAlign: "center",
                mb: { xs: 2, md: 0 },
                background: `url('${whiteBg}')`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                p: 3,
              }}
            >
              <Typography
                sx={{
                  color: "#F5B325",
                  fontSize: "24px",
                  fontFamily: "Russo One",
                }}
              >
                TOKEN NAME
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  fontSize: "24px",
                  fontFamily: "Russo One",
                }}
              >
                CAPITALROCK
              </Typography>
            </Box>
            <Box
              data-aos={"fade-down"}
              data-aos-delay="30"
              data-aos-duration="2000"
              sx={{
                flex: 1,
                textAlign: "center",
                mb: { xs: 2, md: 0 },
                background: `url('${whiteBg}')`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                p: 3,
              }}
            >
              <Typography
                sx={{
                  color: "#F5B325",
                  fontSize: "24px",
                  fontFamily: "Russo One",
                }}
              >
                TOKEN SYMBOL
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  fontSize: "24px",
                  fontFamily: "Russo One",
                }}
              >
                CR
              </Typography>
            </Box>
            <Box
              data-aos={mobileMatches ? "fade-down" : "fade-left"}
              data-aos-delay="30"
              data-aos-duration="2000"
              sx={{
                flex: 1,
                textAlign: "center",
                mb: { xs: 2, md: 0 },
                background: `url('${whiteBg}')`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                p: 3,
              }}
            >
              <Typography
                sx={{
                  color: "#F5B325",
                  fontSize: "24px",
                  fontFamily: "Russo One",
                }}
              >
                TOKEN SYMBOL
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  fontSize: "24px",
                  fontFamily: "Russo One",
                }}
              >
                2.000.000.000
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              mt: { sx: 0, md: 5 },
              display: { xs: "block", md: "flex" },
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Box
              data-aos={mobileMatches ? "fade-down" : "fade-right"}
              data-aos-delay="30"
              data-aos-duration="2000"
              sx={{
                flex: 1,
                maxWidth: { sx: "100%", md: "calc(42% - 10px)" },
                textAlign: "center",
                mb: { xs: 2, md: 0 },
                background: `url('${yellowBg}')`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                p: 3,
              }}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontSize: "22px",
                  fontFamily: "Russo One",
                }}
              >
                TOKEN CONTRACT ADDRESS
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  fontSize: "16px",
                  fontFamily: "Russo One",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                }}
              >
                0x3542a28854c5243656FA5cfA1A2811a32E28C1c8
              </Typography>
            </Box>
            <Box
              data-aos={mobileMatches ? "fade-down" : "fade-left"}
              data-aos-delay="30"
              data-aos-duration="2000"
              sx={{
                flex: 1,
                maxWidth: { sx: "100%", md: "calc(42% - 10px)" },
                textAlign: "center",
                mb: { xs: 2, md: 0 },
                background: `url('${yellowBg}')`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                p: 3,
              }}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontSize: "22px",
                  fontFamily: "Russo One",
                }}
              >
                ACCEPTED CURRENCY
              </Typography>
              <Typography
                sx={{
                  color: "#000000",
                  fontSize: "16px",
                  fontFamily: "Russo One",
                }}
              >
                BNB,USDT(TRC20)(ERC20),BUSD
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default TokenInformation;

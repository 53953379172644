import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
  Stack,
} from "@mui/material";
import { StyledInput } from "../components/SmallComponents/AppComponents";
import {
  heroBg,
  dashboard,
  Chart,
  Bitcoin,
  Eth,
  Cardano,
  Solana,
  TableBg,
} from "../components/SmallComponents/Images";
import Header from "../components/Header";
import axios from "axios";

const initalState = [
  {
    name: "Bitcoin BTC",
    price: "$ 0",
    change: "0%",
    chart: Chart,
    trade: Bitcoin,
  },
  {
    name: "Ethereum ETH",
    price: "$ 0",
    change: "0%",
    chart: Chart,
    trade: Eth,
  },
  {
    name: "Cardano ADA",
    price: "$ 0",
    change: "0%",
    chart: Chart,
    trade: Cardano,
  },
  {
    name: "Solana SOL",
    price: "$ 0",
    change: "0%",
    chart: Chart,
    trade: Solana,
  },
];
function HeroSection() {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  const [email, setEmail] = useState("");
  const [coinsData, setCoinsData] = useState(initalState);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(
          "https://min-api.cryptocompare.com/data/pricemultifull?fsyms=BTC,ETH,ADA,SOL&tsyms=USD"
        );
        setCoinsData([
          {
            name: "Bitcoin BTC",
            price: data?.DISPLAY?.BTC?.USD?.PRICE,
            change: `${data?.DISPLAY?.BTC?.USD?.CHANGEPCTDAY}%`,
            chart: Chart,
            trade: Bitcoin,
          },
          {
            name: "Ethereum ETH",
            price: data?.DISPLAY?.ETH?.USD?.PRICE,
            change: `${data?.DISPLAY?.ETH?.USD?.CHANGEPCTDAY}%`,
            chart: Chart,
            trade: Eth,
          },
          {
            name: "Cardano ADA",
            price: data?.DISPLAY?.ADA?.USD?.PRICE,
            change: `${data?.DISPLAY?.ADA?.USD?.CHANGEPCTDAY}%`,
            chart: Chart,
            trade: Cardano,
          },
          {
            name: "Solana SOL",
            price: data?.DISPLAY?.SOL?.USD?.PRICE,
            change: `${data?.DISPLAY?.SOL?.USD?.CHANGEPCTDAY}%`,
            chart: Chart,
            trade: Solana,
          },
        ]);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);
  return (
    <>
      <Box
        id="home"
        sx={{
          background: `url(${heroBg}) no-repeat center`,
          backgroundSize: "100% 100%",
        }}
        component="section"
        pb={5}
      >
        <Header />
        <Container maxWidth="xl">
          <Grid container mt={10}>
            <Grid item xs={12} md={6}>
              <Box
                data-aos={mobileMatches ? "fade-down" : "fade-right"}
                data-aos-delay="30"
                data-aos-duration="2000"
              >
                <Typography
                  variant="h2"
                  sx={{
                    color: "#F5B325",
                    textAlign: { xs: "center", md: "left" },
                    fontSize: { xs: "40px", md: "50px", lg: "60px" },
                    fontFamily: "Russo One",
                    mb: 2,
                  }}
                >
                  <Box component="span" sx={{ color: "#ffffff" }}>
                    CAPITAL ROCK
                  </Box>
                  <Box component="br" />
                  Is the Best way to
                  <Box component="br" />
                  <Box component="span" sx={{ color: "#ffffff" }}>
                    invest
                  </Box>{" "}
                  your money
                </Typography>
                <Typography
                  sx={{
                    color: "#ffffff",
                    fontSize: "16px",
                    textAlign: { xs: "center", md: "left" },
                    fontFamily: "Montserrat",
                    mb: { xs: 5, md: 8 },
                  }}
                >
                  CapitalRock, an innovative asset management Token, envisions
                  creating a robust investment ecosystem by strategically
                  allocating user funds across 400 companies spanning diverse
                  industries. With a primary focus on the dynamic tech sector,
                  CapitalRock aims to foster a scalable market that empowers
                  both investors and users.
                </Typography>
                <Box
                  sx={{
                    width: "450px",
                    maxWidth: "100%",
                    margin: { xs: "auto", md: "0" },
                  }}
                >
                  <Box component="form" position="relative">
                    <StyledInput
                      type="email"
                      placeholder="Email Address"
                      borderStatus={true}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                      variant="contained"
                      sx={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        height: "100%",
                        borderRadius: "10px",
                        px: { xs: 1.5, sm: 3 },
                        color: "#000000",
                        fontWeight: "600",
                        background:
                          "linear-gradient(180deg, #D09B03 0%, #FEF9C8 35.06%, #D38D00 74.31%, #FFF8C4 116%)",
                      }}
                    >
                      Get Started
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              px={{ xs: 0, md: 2 }}
              mt={{ xs: 5, md: 0 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack
                data-aos={mobileMatches ? "fade-up" : "fade-left"}
                data-aos-delay="30"
                data-aos-duration="2000"
              >
                <Box
                  component="img"
                  sx={{ maxWidth: "100%" }}
                  src={dashboard}
                  alt="dashboard"
                />
              </Stack>
            </Grid>
            <Grid xs={12} item mt={8}>
              <Box
                data-aos={"fade-up"}
                data-aos-delay="30"
                data-aos-duration="2000"
                sx={{
                  background: `url('${TableBg}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  px: 2,
                  py: 8,
                }}
              >
                <TableContainer
                  component={Paper}
                  sx={{ backgroundColor: "transparent", boxShadow: "unset" }}
                >
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" sx={{ color: "#ffffff" }}>
                          #
                        </TableCell>
                        <TableCell align="left" sx={{ color: "#ffffff" }}>
                          Name
                        </TableCell>
                        <TableCell align="left" sx={{ color: "#ffffff" }}>
                          Price
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#ffffff" }}>
                          Change
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#ffffff" }}>
                          Chart
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#ffffff" }}>
                          Trade
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {coinsData.map((row, i) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{ fontSize: "12px", color: "#ffffff" }}
                          >
                            {i + 1}
                          </TableCell>
                          <TableCell align="left" component="th" scope="row">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                component="img"
                                mr={1.5}
                                src={row.trade}
                                alt="chart"
                              />
                              <Typography
                                sx={{ fontSize: "12px", color: "#ffffff" }}
                              >
                                {row.name}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ fontSize: "12px", color: "#ffffff" }}
                          >
                            {row.price}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ fontSize: "12px", color: "#ffffff" }}
                          >
                            {row.change}
                          </TableCell>
                          <TableCell align="center">
                            <Box component="img" src={row.chart} alt="chart" />
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              sx={{
                                fontSize: "10px",
                                px: 0,
                                py: 0.5,
                                borderRadius: "20px",
                                color: "#ffffff",
                                backgroundColor: "#047601",
                                "&:hover": {
                                  backgroundColor: "#047601",
                                  opacity: 0.9,
                                },
                              }}
                            >
                              Buy
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default HeroSection;

import { Box, Stack } from "@mui/material";
import React from "react";
import { StyledTitle } from "../components/SmallComponents/AppComponents";
import PatnersMarquee from "../components/SmallComponents/PatnersMarquee";
import {
  bscScanLogo,
  coinGekoLogo,
  coinMarketCapLogo,
  coinPaprikaLogo,
} from "../components/SmallComponents/Images";

function OurPatners() {
  return (
    <Box mt={10}>
      <Stack mb={4}>
        <StyledTitle textAlign="center" colorStatus={true}>
          {" "}
          Our Patners
        </StyledTitle>
        <Box
          sx={{
            width: { xs: "38%", sm: "27%", xl: "17%", lg: "19%", md: "21%" },
            margin: "auto",
            height: "4px",
            background: " #F5B325",
          }}
        />
      </Stack>
      <PatnersMarquee
        patnerArray={[
          coinGekoLogo,
          coinPaprikaLogo,
          coinMarketCapLogo,
          bscScanLogo,
        ]}
      />
    </Box>
  );
}

export default OurPatners;

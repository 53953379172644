import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { StyledTitle } from "../components/SmallComponents/AppComponents";
import {
  tokenomicsGraph,
  tradingBg,
  coinIcon,
} from "../components/SmallComponents/Images";

function Tokenomics() {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  return (
    <>
      <Box
        sx={{
          background: `url('${tradingBg}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          py: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 1.5,
          }}
        >
          <Box
            component="img"
            sx={{ width: "50px", maxWidth: "100%", mr: 1.5 }}
            src={coinIcon}
            alt=""
          />
          <Typography
            sx={{ fontSize: "22px", color: "#ffffff", fontFamily: "Russo One" }}
          >
            0.626657 USD
            <Box component="span" sx={{ fontSize: "18px", color: "#09A352" }}>
              (0.05%)
            </Box>
          </Typography>
        </Box>
        <Grid
          container
          sx={{
            borderTop: "1px solid rgba(255,255,255,0.5)",
            borderBottom: "1px solid rgba(255,255,255,0.5)",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              borderRight: {
                sx: "unset",
                sm: "1px solid rgba(255,255,255,0.5)",
              },
              borderBottom: {
                xs: "1px solid rgba(255,255,255,0.5)",
                sm: "unset",
              },
              py: 1.3,
              pl: { xs: 0, md: 30 },
            }}
          >
            <Stack
              data-aos={mobileMatches ? "fade-down" : "fade-right"}
              data-aos-delay="30"
              data-aos-duration="2000"
            >
              <Typography
                sx={{
                  fontSize: "22px",
                  color: "#F5B325",
                  textAlign: "center",
                  fontFamily: "Russo One",
                }}
              >
                RANK
              </Typography>

              <Typography
                sx={{
                  fontSize: "18px",
                  color: "#ffffff",
                  textAlign: "center",
                  fontFamily: "Russo One",
                }}
              >
                3440
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} py={1.3} pr={{ xs: 0, md: 30 }}>
            <Stack
              data-aos={mobileMatches ? "fade-down" : "fade-left"}
              data-aos-delay="30"
              data-aos-duration="2000"
            >
              <Typography
                sx={{
                  fontSize: "22px",
                  color: "#F5B325",
                  textAlign: "center",
                  fontFamily: "Russo One",
                }}
              >
                VOLUME
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "#ffffff",
                  textAlign: "center",
                  fontFamily: "Russo One",
                }}
              >
                $158.92 K USD
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Typography
          sx={{
            fontSize: "22px",
            color: "#F5B325",
            textAlign: "center",
            fontFamily: "Russo One",
            mt: 1.5,
          }}
        >
          Powered by CoinMarketCap
        </Typography>
      </Box>
      <Box py={6}>
        <Container maxWidth="xl">
          <Stack mb={4}>
            <StyledTitle textAlign="center" colorStatus={true}>
              {" "}
              TOKENOMICS
            </StyledTitle>
            <Box
              sx={{
                width: {
                  xs: "38%",
                  sm: "27%",
                  xl: "17%",
                  lg: "19%",
                  md: "21%",
                },
                margin: "auto",
                height: "4px",
                background: " #F5B325",
              }}
            />
          </Stack>
          <Stack my={2} alignItems={"center"}>
            <Box
              component={"img"}
              alt=""
              src={tokenomicsGraph}
              width={{ xs: "100%", sm: "80%", md: "60%" }}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  transition: "0.3s",
                  transform: "scale(0.9)",
                },
              }}
            />
          </Stack>
        </Container>
      </Box>

      {/* <div
        className="coinmarketcap-currency-widget"
        data-currencyid="27910"
        data-base="USD"
        data-secondary=""
        data-ticker="true"
        data-rank="true"
        data-marketcap="true"
        data-volume="true"
        data-statsticker="true"
        data-stats="USD"
      ></div>
     */}
    </>
  );
}

export default Tokenomics;

import { Box } from "@mui/material";
import React from "react";
import Marquee from "react-fast-marquee";
function PatnersMarquee({ head, patnerArray }) {
  return (
    <Box
      sx={{
        background: "#EFEFEF",
        py: 4,
      }}
    >
      {/* <Container maxWidth="xl"> */}
      <Marquee
        speed={50}
        pauseOnClick={true}
        pauseOnHover={true}
        gradient={false}
        autoFill={true}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          width="100%"
        >
          {patnerArray.map((img, i) => (
            // <a key={img + i} href={link} style={{ textDecoration: "none" }}>
            <Box
              key={img}
              component={"img"}
              alt={""}
              src={img}
              width="200px"
              marginRight={"100px"}
            />
            // </a>
          ))}
        </Box>
      </Marquee>
      {/* </Container> */}
    </Box>
  );
}

export default PatnersMarquee;

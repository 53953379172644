import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  facebook,
  footerBg,
  linkdin,
  logo,
  medium,
} from "./SmallComponents/Images";
import { StyledInput } from "./SmallComponents/AppComponents";
import { HashLink } from "react-router-hash-link";

const footerArray = [
  {
    head: "Company",
    textArray: [
      {
        name: "About",
        link: "/#about",
      },
      {
        name: "Whitepaper",
        link: "/#whitepaper",
      },
      {
        name: "Roadmap",
        link: "/#roadmap",
      },
      {
        name: "FAQs",
        link: "/#faq",
      },
    ],
  },
  {
    head: "Support",
    textArray: [
      {
        name: "Support",
        link: "mailto:support@capitalrock.ch",
      },
      {
        name: "Privacy",
        link: "/privacy.pdf",
      },
      {
        name: "Terms",
        link: "/terms.pdf",
      },
    ],
  },
];

const socialArray = [
  {
    img: facebook,
    link: "#",
  },
  {
    img: linkdin,
    link: "#",
  },
  {
    img: medium,
    link: "#",
  },
];

function Footer() {
  const [email, setEmail] = useState("");
  return (
    <Box
      mt={2}
      sx={{
        backgroundColor: "#1F1F20",
        pt: 5,
        pb: 3,

        background: `url(${footerBg}) no-repeat bottom left`,
        backgroundSize: { xs: "100% 50%", sm: "30% 60%" },
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={{ xs: 4, md: 2 }} justifyContent={"center"}>
          <Grid item xs={12} sm={8} md={3}>
            <Stack alignItems={{ xs: "center", md: "flex-start" }}>
              <Box component={"img"} alt="" src={logo} width="190px" />
              <Typography
                variant="body1"
                sx={{
                  mt: 2,
                  color: "#ffffff",
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text everLorem Ipsum is simply dummy text of the printing and
                typesetting printing and typesetting
              </Typography>
            </Stack>
          </Grid>

          {footerArray?.map(({ head, textArray }, i) => (
            <Grid
              key={head + i}
              item
              xs={6}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Stack>
                <Typography
                  variant="h2"
                  sx={{
                    color: "#ffffff",
                    fontSize: { xs: "20px", sm: "22px" },
                    fontFamily: "Russo One",
                    fontWeight: "400",
                    lineHeight: "36px",
                  }}
                >
                  {head}
                </Typography>
                <Stack mt={2} gap={2}>
                  {textArray?.map(({ name, link }, index) =>
                    i === 1 ? (
                      <a
                        href={link}
                        key={name}
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            color: "#ffffff",
                            fontSize: { xs: "16px", sm: "18px" },
                            fontFamily: "Roboto",
                            fontWeight: "400",
                            cursor: "pointer",

                            "&:hover": {
                              color: "#F5B325",
                            },
                          }}
                        >
                          {name}
                        </Typography>
                      </a>
                    ) : (
                      <HashLink
                        key={name}
                        smooth
                        to={link}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            color: "#ffffff",
                            fontSize: { xs: "16px", sm: "18px" },
                            fontFamily: "Roboto",
                            fontWeight: "400",
                            cursor: "pointer",

                            "&:hover": {
                              color: "#F5B325",
                            },
                          }}
                        >
                          {name}
                        </Typography>
                      </HashLink>
                    )
                  )}
                </Stack>
              </Stack>
            </Grid>
          ))}
          <Grid item xs={12} sm={8} md={3}>
            <Stack>
              <Typography
                variant="h2"
                sx={{
                  color: "#ffffff",
                  fontSize: { xs: "20px", sm: "22px" },
                  fontFamily: "Russo One",
                  fontWeight: "400",
                  lineHeight: "36px",
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                Sign Up to get Latest Updates
              </Typography>
              <Stack my={2}>
                <StyledInput
                  type={"email"}
                  fullWidth
                  variant="outlined"
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box
                          sx={{
                            marginRight: "-14px",
                          }}
                        >
                          <Button
                            sx={{
                              width: { xs: "120px", sm: "140px" },
                              fontSize: "14px",
                              py: 2,
                              fontWeight: "300",
                              borderRadius: "0px",
                              color: "#ffffff",
                              background: "#F5B325",
                              textTransform: "capitalize",
                              fontFamily: "Roboto",
                              "&:hover": {
                                background: "#F5B325",
                              },
                            }}
                          >
                            Subcribe Now
                          </Button>
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Stack
                flexDirection={"row"}
                justifyContent={{ xs: "center", md: "left" }}
              >
                {socialArray?.map(({ img, link }) => (
                  <IconButton key={img} href={link}>
                    <Box component={"img"} alt="" src={img} width="40px" />
                  </IconButton>
                ))}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Typography
          variant="body1"
          sx={{
            mt: { xs: 4, md: 8 },
            color: "#ffffff",
            fontSize: { xs: "14px", sm: "16px" },
            fontFamily: "Roboto",
            fontWeight: "400",
            textAlign: "center",
          }}
        >
          Copyright © 2024 Translator All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;

import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { StyledTitle } from "../components/SmallComponents/AppComponents";
import {
  roadMap1,
  roadMap2,
  roadMap3,
  roadMapArrow1,
  roadMapArrow2,
  roadMapbg1,
  roadMapbg2,
} from "../components/SmallComponents/Images";
import TokenInformation from "./TokenInformation";

const arry = [
  [
    "Token created on Bscscan Bep-20",
    "Complete Audit by a reputable audit firm",
    "Liquidation added on Pancakeswap",
    "Marketing campaigns Via Instagram, Facebook and Telegram",
    "Logo updated on Bscscan",
  ],
  [
    "Listing on Coinmarketcap",
    "Listing on Coingecko",
    "Metamask logo and price Updated",
    "15,000 transactions swaps completed",
    "Trust Wallet logo and price updated",
  ],
  [
    "Opening ceremoney of physical offices in Europe",
    "Listing on Azbit Crypto Exchange",
    "Listing on Dex-Trade Exchange",
    "Orders of ATM Machines (under process)",
    "Trading Application Development",
    "CapitalRock VPN Development",
    "Marketing boosted 2X All Social Media",
  ],
  [
    "Listing On Tier2 Exchange LBank",
    "Trade License Company In Dubai",
    "Huge Binance AMA Campaigns",
    "CoinTelegraph Press Release Officially",
    "Listing On MEXC Exchange",
    "Trading Competition On MEXC Exchange.",
  ],
  ["Listing On Tier 1 Exchange MEXC", "Coming Soon", "Coming Soon"],
];

function RoadMap() {
  const matches = useMediaQuery("(max-width:900px)");
  const mobileMatches = useMediaQuery("(max-width:650px)");
  return (
    <Box
      id="roadmap"
      sx={{
        background: `url(${roadMapbg1}) no-repeat center, url(${roadMapbg2}) no-repeat center`,
        backgroundSize: "100% 100%",
        backgroundColor: "#000000",
        py: 4,
      }}
    >
      <Container maxWidth="xl">
        <StyledTitle textAlign="center" colorStatus={true}>
          {" "}
          RoadMap
        </StyledTitle>
        <Box
          sx={{
            width: { xs: "36%", sm: "24%", xl: "14%", lg: "16%", md: "18%" },
            margin: "auto",
            height: "4px",
            background: " #F5B325",
          }}
        />
        <Stack
          gap={4}
          mt={4}
          py={3}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {arry?.map((item, i) => (
            <Stack
              key={`phase${i}`}
              flexDirection={"row"}
              justifyContent={"center"}
              width="100%"
            >
              {!matches && (
                <Stack width="40%" mr={-0.5}>
                  {i % 2 !== 0 && (
                    <>
                      <Stack
                        flexDirection={"row-reverse"}
                        gap={2}
                        alignItems={"center"}
                        pt={2}
                      >
                        <Box
                          component={"img"}
                          alt=""
                          src={roadMapArrow2}
                          width="110px"
                        />
                        <Typography
                          data-aos={mobileMatches ? "fade-down" : "fade-right"}
                          data-aos-delay="30"
                          data-aos-duration="2000"
                          variant="h2"
                          sx={{
                            color: "#F5B325",
                            fontSize: "32px",
                            fontFamily: "Russo One",
                            fontWeight: "400",
                          }}
                        >
                          Phase {i + 1}
                        </Typography>
                      </Stack>
                      <Stack
                        data-aos={mobileMatches ? "fade-down" : "fade-right"}
                        data-aos-delay="30"
                        data-aos-duration="2000"
                        sx={{
                          color: "#FFFFFF",
                          fontSize: "16px",
                          fontFamily: "Roboto",
                          textAlign: "right",
                          fontWeight: "300",
                          lineHeight: "26px",
                        }}
                      >
                        <ol
                          className="listRoadMap"
                          style={{ paddingRight: "20px" }}
                        >
                          {item?.map((text) => (
                            <li
                              key={text}
                              className="listRoadMapChild"
                              style={{ paddingTop: "8px" }}
                            >
                              {text}
                            </li>
                          ))}
                        </ol>
                      </Stack>
                    </>
                  )}
                </Stack>
              )}

              <Stack width={{ xs: "3%", sm: "2%" }}>
                <Box
                  component={"img"}
                  alt=""
                  src={
                    i === 0
                      ? roadMap1
                      : arry.length - 1 === i
                      ? roadMap3
                      : roadMap2
                  }
                  width={{ xs: "100%", sm: "100%" }}
                  height={"100%"}
                />
              </Stack>
              <Stack width={matches ? "96%" : "40%"} ml={-0.1}>
                {(matches || i % 2 === 0) && (
                  <>
                    <Stack
                      flexDirection={"row"}
                      gap={{ xs: 1, sm: 2 }}
                      alignItems={"center"}
                      pt={2}
                    >
                      <Box
                        component={"img"}
                        alt=""
                        src={roadMapArrow1}
                        width={{ xs: "70px", sm: "110px" }}
                      />
                      <Typography
                        data-aos={mobileMatches ? "fade-down" : "fade-left"}
                        data-aos-delay="30"
                        data-aos-duration="2000"
                        variant="h2"
                        sx={{
                          color: "#F5B325",
                          fontSize: { xs: "26px", sm: "32px" },
                          fontFamily: "Russo One",
                          fontWeight: "400",
                        }}
                      >
                        Phase {i + 1}
                      </Typography>
                    </Stack>
                    <Stack
                      data-aos={mobileMatches ? "fade-down" : "fade-left"}
                      data-aos-delay="30"
                      data-aos-duration="2000"
                      sx={{
                        color: "#FFFFFF",
                        fontSize: { xs: "14px", sm: "16px" },
                        fontFamily: "Roboto",
                        fontWeight: "300",
                        lineHeight: "26px",
                      }}
                    >
                      <ol
                        className="listRoadMap"
                        style={{ paddingLeft: "20px" }}
                      >
                        {item?.map((text, i) => (
                          <li
                            key={text + i}
                            className="listRoadMapChild"
                            style={{ paddingTop: "8px" }}
                          >
                            {text}
                          </li>
                        ))}
                      </ol>
                    </Stack>
                  </>
                )}
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Container>
      <TokenInformation />
    </Box>
  );
}

export default RoadMap;

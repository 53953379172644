// import { useContext, useState, useEffect } from "react";
// import { useNetwork } from "wagmi";
// import { AppContext } from "./utils";
// import NetworkSwitch from "./NetworkSwitch";
import { Route, Routes } from "react-router-dom";
import {
  HeroSection,
  RoadMap,
  Technology,
  OurPatners,
  Exchanges,
  Tokenomics,
  Team,
  Faq,
  Documents,
  CapitalRock,
  Whitepaper,
  ContactForm,
} from "./pages";
import Footer from "./components/Footer";
import { Box } from "@mui/material";
import { roadMapbg1 } from "./components/SmallComponents/Images";
import AOS from "aos";
import "aos/dist/aos.css";
function App() {
  // const { account } = useContext(AppContext);
  // const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  // const { chain } = useNetwork();

  // useEffect(() => {
  //   if (account && chain && chain?.id !== 56) {
  //     setOpenNetworkSwitch(true);
  //   }
  // }, [chain, account]);
  AOS.init();
  return (
    <>
      {/* <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} /> */}

      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeroSection />
              <CapitalRock />
              <Documents />
              <Box
                sx={{
                  background: `url(${roadMapbg1}) no-repeat center`,
                  backgroundSize: "100% 100%",
                }}
              >
                <Technology />
                <Whitepaper />
              </Box>
              <RoadMap />
              <Box
                sx={{
                  background: `url(${roadMapbg1}) no-repeat center`,
                  backgroundSize: "100% 100%",
                }}
              >
                <Tokenomics />
                <Team />
              </Box>

              <Exchanges />
              <Box
                sx={{
                  background: `url(${roadMapbg1}) no-repeat center`,
                  backgroundSize: "100% 100%",
                }}
              >
                <Faq />
                <ContactForm />
              </Box>

              <OurPatners />
              <Footer />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;

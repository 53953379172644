import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import {
  StyledSubTitle,
  StyledTitle,
} from "../components/SmallComponents/AppComponents";
import {
  tech1,
  tech2,
  tech3,
  tech4,
  tech5,
  techBgLeft,
  techBgRight,
} from "../components/SmallComponents/Images";
import Audit from "./Audit";

const arry = [
  {
    img: tech1,
    head: "End-to-end encrypted",
    para: "Encryption is the process of using an algorithm that transforms standard text characters into an unreadable format. End-to-end encryption uses this same process, too.",
  },
  {
    img: tech2,
    head: "Easy to Buy & Sell Token",
    para: "CapitalRock is very easy to buy through different exchanges and also direct through us.",
  },
  {
    img: tech3,
    head: "Early Profit",
    para: "CapitalRock will allow you to get early profit and to fullfil your dream.",
  },
  {
    img: tech4,
    head: "Fast Transaction",
    para: "Simply sign up to start trading CapitalRock instantly with both local and global users.",
  },
  {
    img: tech5,
    head: "Safe and Secure",
    para: "CapitalRock is a fair and safe project with a complete decentralized policy. The users can be a part of CapitalRock with complete surety.",
  },
];

function Technology() {
  const matchesTwo = useMediaQuery("(max-width:1350px)");
  const matches = useMediaQuery("(max-width:1050px)");
  const mobileMatches = useMediaQuery("(max-width:650px)");
  return (
    <Box py={6}>
      <Audit />
      <Container maxWidth="xl">
        <StyledTitle textAlign="center"> Our Technology Services</StyledTitle>
        <StyledSubTitle textAlign="center" mt={2}>
          We deal in user privacy our aim is to keep the anonymity of user as
          much as we can.
        </StyledSubTitle>
        <Stack gap={4} mt={4} py={3}>
          {arry?.map(({ img, head, para }, index) => (
            <Stack
              key={head}
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems={matches ? "center" : ""}
            >
              {!matches && (
                <Stack
                  data-aos={mobileMatches ? "fade-up" : "fade-right"}
                  data-aos-delay="30"
                  data-aos-duration="2000"
                  width={"30%"}
                  sx={{
                    background: `url(${
                      index % 2 === 0 ? techBgRight : ""
                    }) no-repeat center`,
                    backgroundSize: "100% 100%",
                    pl: 6,
                    pt: { xs: 1, sm: 3 },
                    pb: 3,
                    pr: 3,
                    justifyContent: "center",
                  }}
                >
                  {index % 2 === 0 && (
                    <>
                      {" "}
                      <Typography
                        variant="h4"
                        sx={{
                          color: "#F5B325",
                          fontSize: "18px",
                          fontFamily: "Roboto",
                          fontWeight: "400",
                        }}
                      >
                        {head}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          mt: 1,
                          color: "#ffffff",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                          fontWeight: "400",
                        }}
                      >
                        {para}
                      </Typography>
                    </>
                  )}
                </Stack>
              )}
              <Stack
                data-aos={"flip-down"}
                data-aos-delay="30"
                data-aos-duration="2000"
                width={{
                  xs: "30%",
                  sm: "20%",
                  md: matches ? "15%" : "15%",
                  lg: matchesTwo ? "15%" : "10%",
                  xl: "10%",
                }}
              >
                <Box
                  component={"img"}
                  alt=""
                  src={img}
                  width={{ xs: "100%", sm: "100%" }}
                  height={{ xs: "80%", sm: "100%" }}
                />
              </Stack>
              <Stack
                data-aos={mobileMatches ? "fade-down" : "fade-left"}
                data-aos-delay="30"
                data-aos-duration="2000"
                width={{ xs: "80%", md: matches ? "45%" : "30%" }}
                sx={{
                  background: `url(${
                    matches || index % 2 === 1 ? techBgLeft : ""
                  }) no-repeat center`,
                  backgroundSize: "100% 100%",
                  pl: { xs: 3, sm: 6 },
                  pt: { xs: 2, sm: 3 },
                  pb: { xs: 2, sm: 3 },
                  pr: { xs: 2, sm: 3 },
                  justifyContent: "center",
                }}
              >
                {(matches || index % 2 === 1) && (
                  <>
                    <Typography
                      variant="h4"
                      sx={{
                        color: "#F5B325",
                        fontSize: { xs: "16px", sm: "18px" },
                        fontFamily: "Roboto",
                        fontWeight: "400",
                      }}
                    >
                      {head}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        mt: 1,
                        color: "#ffffff",
                        fontSize: { xs: "10px", sm: "14px" },
                        fontFamily: "Roboto",
                        fontWeight: "400",
                      }}
                    >
                      {para}
                    </Typography>
                  </>
                )}
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Container>
    </Box>
  );
}

export default Technology;

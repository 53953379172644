import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import {
  StyledInputTwo,
  StyledTitle,
} from "../components/SmallComponents/AppComponents";
import { FaPhoneVolume } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaMedium } from "react-icons/fa6";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { FaTelegramPlane } from "react-icons/fa";

const arry = [
  {
    icon: <FaPhoneVolume style={{ color: "#ffffff", fontSize: "23px" }} />,
    text: "(+971)504245227",
    link: "tel:+971504245227",
  },
  {
    icon: <IoMdMail style={{ color: "#ffffff", fontSize: "23px" }} />,
    text: "support@capitalrock.ch",
    link: "mailto:support@capitalrock.ch",
  },
  {
    icon: <FaLocationDot style={{ color: "#ffffff", fontSize: "23px" }} />,
    text: "Dubai Silicon Oasis, DDP, Building A1, Dubai, United Arab Emirates",
  },
];

const socialArray = [
  {
    icon: <FaTwitter style={{ fontSize: "20px" }} />,
    link: "https://x.com/capitalrock_ag?s=21&t=HZvMRLJ_sHNCA4NWuSGX-w",
    hoverColor: "#24A3F1",
  },
  {
    icon: <FaInstagram style={{ fontSize: "20px" }} />,
    link: "#",
    hoverColor: "#FF164C",
  },
  {
    icon: <FaFacebookF style={{ fontSize: "20px" }} />,
    link: "#",
    hoverColor: "#4267B2",
  },
  {
    icon: <FaLinkedinIn style={{ fontSize: "20px" }} />,
    link: "#",
    hoverColor: "#0077B5",
  },
  // {
  //   icon: <FaMedium style={{ fontSize: "20px" }} />,
  //   link: "#",
  //   hoverColor: "#080808",
  // },
  {
    icon: <FaTelegramPlane style={{ fontSize: "20px" }} />,
    link: "https://t.me/capitalrock1",
    hoverColor: "#0088CC",
  },
];

const labelStyle = {
  color: "#707070",
  fontFamily: "Roboto",
  fontWeight: "400",
  forntSize: "20px",
  ml: 1,
};

function ContactForm() {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  const [formInputData, setFormInputData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    companyName: "",
    requirement: "",
    termsAndConditions: true,
  });

  const handleFormChange = (e) => {
    setFormInputData({ ...formInputData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    setFormInputData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      companyName: "",
      requirement: "",
      termsAndConditions: true,
    });
  };

  return (
    <Box py={6}>
      <Container maxWidth="xl">
        <Stack mb={4}>
          <StyledTitle textAlign="center" colorStatus={true}>
            {" "}
            Get In Touch
          </StyledTitle>
          <Box
            sx={{
              width: { xs: "40%", sm: "29%", xl: "19%", lg: "21%", md: "23%" },
              margin: "auto",
              height: "4px",
              background: " #F5B325",
            }}
          />
        </Stack>
        <Stack
          sx={{
            background: "#EFEFEF",
            borderRadius: "12px",
            px: 1.5,
            pt: 1.5,
            pb: { xs: 3, md: 7.5 },
          }}
        >
          <Grid container spacing={{ xs: 1, sm: 2 }} justifyContent={"center"}>
            <Grid item xs={12} md={6}>
              <Stack
                data-aos={mobileMatches ? "fade-down" : "fade-right"}
                data-aos-delay="30"
                data-aos-duration="2000"
                sx={{
                  background:
                    "linear-gradient(to bottom, #543e0e 0%, #040404 66%, #040404 100%)",
                  borderRadius: "12px",
                  pt: 6,
                  pl: 5,
                  pb: { xs: 6, md: 0 },
                  height: { xs: "inherit", md: "100%" },
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: "#ffffff",
                    fontSize: { xs: "22px", sm: "32px" },
                    fontFamily: "Russo One",
                    fontWeight: "400",
                  }}
                >
                  Contact Information
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    color: "#ffffff",
                    fontSize: { xs: "16px", sm: "20px" },
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    lineHeight: "26px",
                    mt: 2,
                  }}
                >
                  Say something to start a conversation!
                </Typography>
                <Stack my={{ xs: 3, sm: 4 }}>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#ffffff",
                      fontSize: { xs: "16px", sm: "20px" },
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      mt: 2,
                    }}
                  >
                    <a
                      href="mailto:support@capitalrock.ch"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color: "#ffffff" }}
                    >
                      support@capitalrock.ch
                    </a>
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#ffffff",
                      fontSize: { xs: "16px", sm: "20px" },
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      mt: 2,
                    }}
                  >
                    <Box
                      component={"span"}
                      sx={{
                        fontWeight: "600",
                      }}
                    >
                      Phone
                    </Box>{" "}
                    <a
                      href="tel:+971504245227"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "#ffffff", textDecoration: "none" }}
                    >
                      (+971)504245227
                    </a>
                  </Typography>
                </Stack>
                <Stack>
                  {arry?.map(({ icon, text, link }) => (
                    <Stack
                      key={text}
                      flexDirection={"row"}
                      gap={2}
                      alignItems={"center"}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          top: "10px",
                        }}
                      >
                        {icon}
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#ffffff",
                          fontSize: { xs: "14px", sm: "16px" },
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          mt: 2,
                        }}
                      >
                        {link ? (
                          <a
                            href={link}
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none", color: "#ffffff" }}
                          >
                            {text}
                          </a>
                        ) : (
                          `${text}`
                        )}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
                <Stack
                  flexDirection={"row"}
                  mt={{ xs: 8, md: 17 }}
                  gap={{ xs: 1, sm: 2 }}
                >
                  {socialArray?.map(({ icon, link, hoverColor }, i) => (
                    <IconButton
                      key={i * Math.random() + i + "socialIcon"}
                      href={link}
                      target="_blank"
                      sx={{
                        background: "#263238",
                        color: "#ffffff",
                        "&:hover": {
                          background: "#ffffff",
                          color: hoverColor,
                          transition: "0.3s",
                        },
                      }}
                    >
                      {icon}
                    </IconButton>
                  ))}
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                data-aos={mobileMatches ? "fade-up" : "fade-left"}
                data-aos-delay="30"
                data-aos-duration="2000"
                sx={{
                  pt: 6,
                  px: 2,
                }}
              >
                <Grid container spacing={4} justifyContent={"center"}>
                  <Grid item xs={12} sm={6}>
                    <Stack>
                      <FormLabel htmlFor="firstName" sx={labelStyle}>
                        First Name
                      </FormLabel>
                      <StyledInputTwo
                        name="firstName"
                        type="text"
                        id="firstName"
                        placeholder="John"
                        value={formInputData.firstName}
                        onChange={handleFormChange}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack>
                      <FormLabel htmlFor="lastName" sx={labelStyle}>
                        Last Name
                      </FormLabel>
                      <StyledInputTwo
                        name="lastName"
                        type="text"
                        id="lastName"
                        placeholder="Doe"
                        value={formInputData.lastName}
                        onChange={handleFormChange}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack>
                      <FormLabel htmlFor="email" sx={labelStyle}>
                        Email
                      </FormLabel>
                      <StyledInputTwo
                        name="email"
                        type="email"
                        id="email"
                        placeholder="jhondoe@mail.com"
                        value={formInputData.email}
                        onChange={handleFormChange}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack>
                      <FormLabel htmlFor="phoneNumber" sx={labelStyle}>
                        Phone Number
                      </FormLabel>
                      <PhoneInput
                        inputProps={{
                          id: "phoneNumber",
                        }}
                        placeholder="0000000"
                        inputStyle={{
                          color: "#8D8D8D",
                          fontSize: "16px",
                          fontWeight: "400",
                          fontFamily: "Roboto",
                          width: "100%",
                          background: "transparent",
                          border: "none",
                          borderRadius: "0px",
                          borderBottom: "1px solid #707070",
                        }}
                        country={"us"}
                        value={formInputData.phoneNo}
                        onChange={(phone) =>
                          setFormInputData({
                            ...formInputData,
                            phoneNo: phone,
                          })
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack>
                      <StyledInputTwo
                        name="companyName"
                        type="text"
                        id="companyName"
                        placeholder="Your company / Organization"
                        value={formInputData.companyName}
                        onChange={handleFormChange}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack>
                      <StyledInputTwo
                        name="requirement"
                        type="text"
                        id="requirement"
                        placeholder="Share your requirement"
                        value={formInputData.requirement}
                        onChange={handleFormChange}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack flexDirection={"row"} alignItems={"center"}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="termsAndConditions"
                        value={formInputData.termsAndConditions}
                        sx={{
                          justifyContent: "center",
                          ".MuiFormControlLabel-label": {
                            fontFamily: "Roboto",
                            fontWeight: "500",
                            fontSize: { xs: "14px", sm: "16px" },
                          },
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              disableRipple={true}
                              checked={formInputData.termsAndConditions}
                              onChange={(e) =>
                                setFormInputData({
                                  ...formInputData,
                                  termsAndConditions: e.target.checked,
                                })
                              }
                              icon={
                                <RadioButtonUncheckedIcon
                                  sx={{ color: "#000000" }}
                                />
                              }
                              checkedIcon={
                                <CheckCircleIcon sx={{ color: "#000000" }} />
                              }
                            />
                          }
                          label="I agree, to terms and conditions"
                        />
                      </RadioGroup>
                    </Stack>
                  </Grid>
                </Grid>
                <Stack
                  alignItems={"end"}
                  mt={{ xs: 6, sm: 7, md: 7, xl: 5, lg: 5 }}
                >
                  <Button
                    sx={{
                      width: "140px",
                      fontSize: "16px",
                      fontWeight: "400",
                      py: 1,
                      color: "#ffffff",
                      background: "#F5B325",
                      fontFamily: "Roboto",
                      borderRadius: "12px",
                      "&:hover": {
                        background: "#F5B325",
                      },
                    }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}

export default ContactForm;

import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { StyledTitle } from "../components/SmallComponents/AppComponents";
import {
  smallFbImg,
  team1,
  team2,
  team3,
} from "../components/SmallComponents/Images";

const teamArray = [
  {
    img: team1,
    name: "Kushtrim Rexhepi",
    title: "CEO",
    para: "With over a decade of experience, Mr. Kushtrim Rexhepi has been a stalwart in sales for large corporations since 2010. Transitioning to the world of cryptocurrencies in 2012, he has excelled in buying and selling digital assets, trading stocks, and investing in startups. More than 10 years, contributing significantly to the success .",
  },
  {
    img: team2,
    name: "Dr.Bernd Merle",
    title: "CFO",
    para: "With effect from November 11, 2023, Dr.Bernd Merle joined the CapitalRock Board of Directors as CFO.The 60-year-old manager looks back on a 25-year successful career in the oil business and has been working as an entrepreneur and investor in various areas since 2013. Dr.Merle brings fundamental experience and knowledge to CapitalRock.",
  },
  {
    img: team3,
    name: "Shaikh Tahir",
    title: "Business Development Manager",
    para: "With a rich background in digital currencies since 2014, Mr. Shaikh Tahir possesses extensive expertise in cryptocurrencies and their strategic optimization. His profound knowledge extends to making digital currencies beneficial, along with established connections in major exchanges.",
  },
];
function Team() {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  return (
    <Box py={6}>
      <Container maxWidth="xl">
        <Stack mb={4}>
          <StyledTitle textAlign="center" colorStatus={true}>
            {" "}
            OUR Team
          </StyledTitle>
          <Box
            sx={{
              width: { xs: "36%", sm: "25%", xl: "15%", lg: "17%", md: "19%" },
              margin: "auto",
              height: "4px",
              background: " #F5B325",
            }}
          />
        </Stack>
        <Stack my={2} alignItems={"center"}>
          <Grid container spacing={4} justifyContent={"center"} my={4}>
            {teamArray?.map(({ img, title, para, name }, i) => (
              <Grid item xs={12} md={4} key={title}>
                <Stack
                  data-aos={
                    mobileMatches
                      ? "fade-down"
                      : i === 0
                      ? "fade-right"
                      : i === 1
                      ? "fade-down"
                      : "fade-left"
                  }
                  data-aos-delay="30"
                  data-aos-duration="2000"
                  gap={2}
                  alignItems={"center"}
                  sx={{
                    pt: { xs: 5, md: 7 },
                    pb: { xs: 5, md: 0 },
                    px: { xs: 3, md: 5 },
                    height: { xs: "inherit", md: "100%" },
                    background: "#121212",
                    borderRadius: "12px",
                    cursor: "pointer",
                    "&:hover": {
                      transition: "0.3s",
                      transform: "scale(0.9)",
                    },
                  }}
                >
                  <Box
                    component={"img"}
                    alt=""
                    src={img}
                    width={
                      i === 0
                        ? { xs: "60%", sm: "50%" }
                        : i === 1
                        ? { xs: "70%", sm: "70%" }
                        : { xs: "70%", sm: "65%" }
                    }
                    sx={{
                      borderRadius: "50%",
                    }}
                  />

                  <Stack>
                    <Typography
                      variant="h3"
                      sx={{
                        color: "#ffffff",
                        fontSize: "22px",
                        fontFamily: "Russo One",
                        lineHeight: "22px",
                        fontWeight: "500",
                        textAlign: "center",
                        mt: 3,
                      }}
                    >
                      {name}
                    </Typography>
                    <Box
                      sx={{
                        mt: 1,
                        width: "100%",
                        height: "4px",
                        background: " #F5B325",
                      }}
                    />
                  </Stack>
                  <Stack
                    flexDirection={"row"}
                    gap={1.5}
                    mt={1}
                    alignItems={"center"}
                  >
                    <Box
                      component={"img"}
                      alt=""
                      src={smallFbImg}
                      width="18px"
                      sx={{
                        cursor: "pointer",
                      }}
                    />
                    <Typography
                      variant="h3"
                      sx={{
                        color: "#ffffff",
                        fontSize: { xs: "15px", sm: "16px" },
                        fontFamily: "Russo One",
                        fontWeight: { xs: "400", md: "500" },
                        textAlign: "center",
                      }}
                    >
                      {title}
                    </Typography>
                  </Stack>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#ffffff",
                      fontSize: "16px",
                      fontFamily: "Roboto",
                      fontWeight: "300",
                      textAlign: "center",
                      mt: 1,
                    }}
                  >
                    {para}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}

export default Team;

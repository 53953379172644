import { Box, Stack } from "@mui/material";
import React from "react";
import { StyledTitle } from "../components/SmallComponents/AppComponents";
import PatnersMarquee from "../components/SmallComponents/PatnersMarquee";
import {
  exchange1,
  exchange2,
  exchange3,
  exchange4,
  exchange5,
} from "../components/SmallComponents/Images";

function Exchanges() {
  return (
    <Box mt={6}>
      <Stack mb={4}>
        <StyledTitle textAlign="center" colorStatus={true}>
          {" "}
          Exchanges
        </StyledTitle>
        <Box
          sx={{
            width: { xs: "36%", sm: "25%", xl: "15%", lg: "17%", md: "19%" },
            margin: "auto",
            height: "4px",
            background: " #F5B325",
          }}
        />
      </Stack>
      <PatnersMarquee
        patnerArray={[exchange1, exchange2, exchange3, exchange4, exchange5]}
      />
    </Box>
  );
}

export default Exchanges;

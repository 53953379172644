import React from "react";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  tradingBg,
  dubaiImg,
  tradingBgMain,
  docImage,
} from "../components/SmallComponents/Images";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper/modules";

function Documents() {
  const matches = useMediaQuery("(max-width:1056px)");
  const matches2 = useMediaQuery("(max-width:989px)");
  const matches3 = useMediaQuery("(max-width:924px)");
  const mobileMatches = useMediaQuery("(max-width:650px)");
  return (
    <Box
      component="section"
      sx={{
        background: `url('${tradingBgMain}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundColor: "#000000",
      }}
      pt={{ xs: 10, md: 30 }}
      pb={{ xs: 10, md: 18 }}
    >
      <Grid container sx={{ display: { xs: "block", md: "none" }, mb: 5 }}>
        <Grid item xs={12} textAlign="center">
          <Box
            data-aos={mobileMatches ? "fade-down" : "fade-right"}
            data-aos-delay="30"
            data-aos-duration="2000"
            component="img"
            sx={{ maxWidth: { xs: "60%", sm: "75%" } }}
            src={dubaiImg}
            alt=""
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          background: `url('${tradingBg}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          py: 5,
        }}
      >
        <Container sx={{ position: "relative" }}>
          <Grid container>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={6}>
              <Stack
                data-aos={mobileMatches ? "fade-up" : "fade-left"}
                data-aos-delay="30"
                data-aos-duration="2000"
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "#F5B325",
                    fontSize: { xs: "26px", sm: "36px" },
                    fontFamily: "Russo One",
                    textAlign: { xs: "center", md: "left" },
                    lineHeight: "40px",
                    mb: 1.5,
                  }}
                >
                  DUBAI TRADING LICENSE
                </Typography>
                <Typography
                  sx={{
                    color: "#ffffff",
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    textAlign: { xs: "center", md: "left" },
                    mb: 1,
                  }}
                >
                  CapitalRock, a leading cryptocurrency firm, has recently
                  obtained a trading license in Dubai, solidifying its position
                  in the global digital finance arena. Headquartered in Dubai's
                  financial district, Capitalrock is now poised to offer a
                  secure and regulated platform for digital asset exchange,
                  emphasizing compliance and transparency. With a focus on
                  providing innovative financial services and investment
                  opportunities, CapitalRock aims to propel the widespread
                  adoption of cryptocurrencies in the mainstream financial
                  landscape while adhering to stringent regulatory standards.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Box
            data-aos={mobileMatches ? "fade-up" : "fade-right"}
            data-aos-delay="30"
            data-aos-duration="2000"
            sx={{
              position: "absolute",
              left: 0,
              bottom: matches3
                ? "0%"
                : matches2
                ? "-8%"
                : matches
                ? "-25%"
                : "-40%",
              display: { xs: "none", md: "block" },
            }}
          >
            <Box
              component="img"
              sx={{ maxWidth: "45%" }}
              src={dubaiImg}
              alt=""
            />
          </Box>
        </Container>
      </Box>
      <Box pt={20}>
        <Box sx={{ textAlign: "center" }}>
          <Box
            component="span"
            sx={{
              color: "#ffffff",
              fontSize: "36px",
              fontFamily: "Russo One",
              borderBottom: "5px solid #F5B325",
            }}
          >
            DOCUMENTS
          </Box>
        </Box>
        <Box mt={10}>
          <Swiper
            navigation={true}
            modules={[Navigation]}
            className="doc-slider"
            loop
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              250: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              600: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
              1024: {
                slidesPerView: 3.9,
                spaceBetween: 50,
              },
            }}
          >
            <SwiperSlide>
              <a
                target="_blank"
                href="./CAPITALROCK LABS-FZCO-COF-DIGITAL.pdf"
                style={{
                  textDecoration: "none",
                }}
              >
                <Box component="img" src={docImage} alt="" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a
                target="_blank"
                href="./CAPITALROCK LABS-FZCO-LIC-NEW-DIGITAL.pdf"
                style={{
                  textDecoration: "none",
                }}
              >
                <Box component="img" src={docImage} alt="" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a
                target="_blank"
                href="./CAPITALROCK LABS-FZCO-ULA.pdf"
                style={{
                  textDecoration: "none",
                }}
              >
                <Box component="img" src={docImage} alt="" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a
                target="_blank"
                href="./CAPITALROCK LABS-FZCO-MOA&AOA.pdf"
                style={{
                  textDecoration: "none",
                }}
              >
                <Box component="img" src={docImage} alt="" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a
                target="_blank"
                href="./CAPITALROCK LABS-FZCO-COF-DIGITAL.pdf"
                style={{
                  textDecoration: "none",
                }}
              >
                <Box component="img" src={docImage} alt="" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a
                target="_blank"
                href="./CAPITALROCK LABS-FZCO-LIC-NEW-DIGITAL.pdf"
                style={{
                  textDecoration: "none",
                }}
              >
                <Box component="img" src={docImage} alt="" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a
                target="_blank"
                href="./CAPITALROCK LABS-FZCO-ULA.pdf"
                style={{
                  textDecoration: "none",
                }}
              >
                <Box component="img" src={docImage} alt="" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a
                target="_blank"
                href="./CAPITALROCK LABS-FZCO-MOA&AOA.pdf"
                style={{
                  textDecoration: "none",
                }}
              >
                <Box component="img" src={docImage} alt="" />
              </a>
            </SwiperSlide>
          </Swiper>
        </Box>
      </Box>
    </Box>
  );
}

export default Documents;

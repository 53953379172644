import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import {
  tradingBg,
  whitePaperSectionImg,
} from "../components/SmallComponents/Images";

function Whitepaper() {
  const matches = useMediaQuery("(max-width:1056px)");
  const matches2 = useMediaQuery("(max-width:989px)");
  const matches3 = useMediaQuery("(max-width:924px)");
  const mobileMatches = useMediaQuery("(max-width:650px)");
  return (
    <Box mt={{ xs: 6, md: 0 }} id={"whitepaper"}>
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          display: { xs: "flex", md: "none" },
        }}
      >
        <Stack
          data-aos={mobileMatches ? "fade-down" : "fade-right"}
          data-aos-delay="30"
          data-aos-duration="2000"
        >
          <Box
            component="img"
            sx={{ maxWidth: { xs: "60%", sm: "45%" }, margin: "auto" }}
            src={whitePaperSectionImg}
            alt=""
          />
        </Stack>
      </Stack>
      <Box
        my={{ xs: 5, md: 25 }}
        sx={{
          background: `url('${tradingBg}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          py: 5,
        }}
      >
        <Container sx={{ position: "relative" }}>
          <Grid container>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={6}>
              <Stack
                data-aos={mobileMatches ? "fade-up" : "fade-left"}
                data-aos-delay="30"
                data-aos-duration="2000"
                position={"relative"}
                alignItems={{ xs: "center", md: "start" }}
                zIndex={1}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "#F5B325",
                    fontSize: { xs: "26px", sm: "36px" },
                    fontFamily: "Russo One",
                    textAlign: { xs: "center", md: "left" },
                    mb: 1.5,
                  }}
                >
                  Read Our Whitepaper
                </Typography>
                <Typography
                  sx={{
                    color: "#ffffff",
                    fontSize: "16px",
                    fontFamily: "Roboto",
                    textAlign: { xs: "center", md: "left" },
                    mb: 1,
                  }}
                >
                  Here is our full documents that help you to understand about
                  us.
                </Typography>
                <Button
                  href="./whitepaper.pdf"
                  target={"_blank"}
                  sx={{
                    mt: 2,
                    width: { xs: "150px", sm: "160px" },
                    fontSize: "16px",
                    fontWeight: "400",
                    boxShadow: "#D09B03 0px 5px 15px",
                    py: 1,
                    color: "#ffffff",
                    background: "#F5B325",
                    fontFamily: "Roboto",
                    borderRadius: "12px",
                    "&:hover": {
                      background: "#F5B325",
                    },
                  }}
                >
                  WHiTE Paper
                </Button>
              </Stack>
            </Grid>
          </Grid>
          <Box
            data-aos={mobileMatches ? "fade-down" : "fade-right"}
            data-aos-delay="30"
            data-aos-duration="2000"
            zIndex={0}
            sx={{
              position: "absolute",
              left: 0,
              bottom: matches3
                ? "0%"
                : matches2
                ? "-8%"
                : matches
                ? "-25%"
                : "-60%",
              display: { xs: "none", md: "block" },
            }}
          >
            <Box
              component="img"
              sx={{ maxWidth: "45%" }}
              src={whitePaperSectionImg}
              alt=""
            />
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default Whitepaper;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import {
  StyledSubTitle,
  StyledTitle,
} from "../components/SmallComponents/AppComponents";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";

const faqTextArray = [
  {
    head: "What is CapitalRock Token?",
    para: "CapitalRock Token is the latest thematic Token that offers its users and holders a range of benefits including investments in different categories, many industries in innovation and blockchain and start-ups and existing successful companies in the stock markets.",
  },
  {
    head: "How do I buy CapitalRock Token?",
    para: "You buy the Token on our official website and get bonus Tokens if you buy early or refer friends, family and community members. You can also sell at any time, there are no obligations.",
  },
  {
    head: "How do I get the Capitalrock Token on my personal Trust Wallet?",
    para: "The Token runs on the BscScan blockchain. You must provide your BNB and Capitalrock address to receive the Tokens. We recommend that you download your trust wallet and add the Capitalrock Token, after which you will receive your purchased Tokens on your trust wallet.",
  },
  {
    head: "Why is the Capitalrock Token different from other tokens?",
    para: (
      <span>
        <ul>
          <li>Capitalrock Token is a shareholder in over 400 companies.</li>
          <li>
            Capitalrock invests in listed companies covering all industries.
          </li>
          <li>
            Capitalrock also invests in blockchain projects and supports
            start-up projects with big visions.
          </li>
          <li>
            So we split into several categories and various industries, so we
            secure the investments over several segments in the long term.
          </li>
        </ul>
      </span>
    ),
  },
];

function Faq() {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  const matches = useMediaQuery("(max-width:750px)");
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel, index) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box id="faq" py={6}>
      <Container maxWidth="xl">
        <StyledTitle colorStatus={true} textAlign="center">
          {" "}
          Frequently Asked Questions
        </StyledTitle>
        <StyledSubTitle colorStatus={true} textAlign="center" mt={2}>
          please check out our FAQs below if you need any support, please get in
          touch using the contact form below.
        </StyledSubTitle>

        <Grid
          container
          spacing={matches ? 2 : 3}
          justifyContent="center"
          mt={matches ? 2 : 6}
          flexDirection={{ xs: "column-reverse", md: "row" }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack
              gap={4}
              sx={{
                width: { xs: "100%", sm: "90%", margin: "auto" },
              }}
            >
              {faqTextArray.map(({ head, para }, i) => (
                <Box
                  data-aos={
                    mobileMatches
                      ? "fade-down"
                      : i % 2 !== 0
                      ? "fade-left"
                      : "fade-right"
                  }
                  data-aos-delay="30"
                  data-aos-duration="2000"
                >
                  <Accordion
                    key={`panel${i + 1}`}
                    expanded={expanded === `panel${i + 1}`}
                    onChange={handleChange(`panel${i + 1}`)}
                    sx={{
                      background:
                        "linear-gradient(to bottom, #543e0e 0%, #040404 66%, #040404 100%)",
                      "&.MuiAccordion-root:before": {
                        height: "0px !important",
                      },
                      width: "100%",
                      m: "auto",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === `panel${i + 1}` ? (
                          <Remove
                            style={{
                              color: "#ffffff",
                              fontSize: "25px",
                            }}
                          />
                        ) : (
                          <Add
                            style={{
                              color: "#ffffff",
                              fontSize: "25px",
                            }}
                          />
                        )
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Box
                        fontSize={matches ? "17px" : "21px"}
                        fontWeight={500}
                        px={matches ? 0 : 1}
                        py={1}
                        color="#ffffff"
                        fontFamily="Russo One"
                        lineHeight={"26px"}
                      >
                        {head}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        backgroundColor: "#EFEFEF",
                      }}
                    >
                      <Box
                        px={matches ? 1 : 2}
                        py={2}
                        fontSize={{ xs: "14px", sm: "16px" }}
                        textAlign="left"
                        fontWeight="400"
                        color="#595757"
                        fontFamily="Roboto"
                        lineHeight={"26px"}
                      >
                        {para}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Faq;

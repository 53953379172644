import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { auditImage } from "../components/SmallComponents/Images";

function Audit() {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  return (
    <Box component="section" sx={{ pt: 5, pb: 10 }}>
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            px={2}
            sx={{
              mb: { xs: 3, md: 0 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              data-aos={"flip-up"}
              data-aos-delay="30"
              data-aos-duration="2000"
            >
              <Box
                component="img"
                sx={{ maxWidth: "100%" }}
                src={auditImage}
                alt="audit"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} px={2}>
            <Box
              data-aos={mobileMatches ? "fade-up" : "fade-left"}
              data-aos-delay="30"
              data-aos-duration="2000"
            >
              <Typography
                variant="h3"
                sx={{
                  color: "#F5B325",
                  fontSize: { xs: "25px", md: "36px" },
                  fontFamily: "Russo One",
                  mb: 2,
                }}
              >
                CAPITALROCK AUDIT
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  mb: 2,
                }}
              >
                A smart contract audit is a rigorous evaluation of the code
                underpinning a cryptocurrency, such as CapitalRock Token (CR).
                This assessment is conducted by a team of experts to ensure the
                security, reliability, and functionality of smart contracts.
                Smart contracts are self-executing agreements with terms encoded
                in blockchain code, serving as the backbone for various
                transactions, including those involving CR.
              </Typography>
              <Typography
                sx={{
                  color: "#F5B325",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  mb: 2,
                }}
              >
                The objectives of a smart contract audit for CR encompass
                several critical aspects:
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  mb: 2,
                }}
              >
                <Box component="span" sx={{ color: "#F5B325" }}>
                  Security:
                </Box>
                 The audit's primary focus is to identify and mitigate potential
                vulnerabilities, ensuring that the smart contract is robust
                against malicious activities, such as unauthorized access,
                overflow errors, or reentrancy attacks.
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  mb: 2,
                }}
              >
                <Box component="span" sx={{ color: "#F5B325" }}>
                  Functionality:
                </Box>
                 It verifies that the smart contract operates as intended,
                including functions like Token transfers, staking, and adherence
                to established cryptocurrency rules.
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  mb: 2,
                }}
              >
                <Box component="span" sx={{ color: "#F5B325" }}>
                  Gas Efficiency:
                </Box>
                Evaluating and optimizing gas efficiency is paramount to reduce
                transaction costs for users, enhancing the cost-effectiveness of
                CR transactions.
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  mb: 2,
                }}
              >
                <Box component="span" sx={{ color: "#F5B325" }}>
                  Compliance:
                </Box>
                 Ensuring that the smart contract complies with all relevant
                regulations and legal requirements is essential to prevent legal
                complications.
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  mb: 2,
                }}
              >
                <Box component="span" sx={{ color: "#F5B325" }}>
                  Code Quality:
                </Box>
                 Auditors assess code quality, emphasizing readability,
                maintainability, and adherence to industry best practices,
                enhancing the overall reliability of CR's smart contract.
              </Typography>
              <Typography
                sx={{
                  color: "#ffffff",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  mb: 2,
                }}
              >
                <Box component="span" sx={{ color: "#F5B325" }}>
                  Scalability:
                </Box>
                 Evaluating scalability considerations is vital for
                accommodating future growth and user adoption, ensuring the
                smart contract can manage increased transaction volumes
                efficiently.
              </Typography>
              <Button
                href="./audit.pdf"
                target="_blank"
                sx={{
                  mt: 2,
                  width: { xs: "120px", sm: "140px" },
                  fontSize: "16px",
                  fontWeight: "400",
                  boxShadow: "#D09B03 0px 5px 15px",
                  py: 1,
                  color: "#ffffff",
                  background: "#F5B325",
                  fontFamily: "Roboto",
                  borderRadius: "12px",
                  "&:hover": {
                    background: "#F5B325",
                  },
                }}
              >
                Read more
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Audit;

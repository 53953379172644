import React from "react";
import {
  Container,
  useMediaQuery,
  SwipeableDrawer,
  Button,
  List,
  ListItemText,
  Box,
  Paper,
  Stack,
  ListItemButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import { logo } from "./SmallComponents/Images";
import { StyledButton, StyledText } from "./SmallComponents/AppComponents";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
    alignItems: "center",
  },
  paper: {
    background: "#181c1e !important",
  },
  hover: {
    "&:hover": {
      color: "#FFB800",
    },
  },
});

const navArray = [
  {
    text: "Home",
    link: "/#home",
  },
  {
    text: "About",
    link: "/#about",
  },
  {
    text: "Whitepaper",
    link: "/#whitepaper",
  },
  {
    text: "Roadmap",
    link: "/#roadmap",
  },
  {
    text: "FAQ,s",
    link: "/#faq",
  },
];

export default function Header() {
  const matches = useMediaQuery("(max-width:1100px)");
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const matches1 = useMediaQuery("(max-width:1279px)");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box display="flex" justifyContent="center" my={2}>
        <Box component={"img"} width="150px" src={logo} alt="" />
      </Box>
      <List>
        {navArray?.map(({ text, link }, i) => (
          <ListItemButton key={text}>
            <HashLink
              smooth
              to={link}
              style={{ textDecoration: "none", margin: "auto" }}
            >
              <ListItemText
                sx={{
                  textTransform: "capitalize",
                  textAlign: "center",
                  textDecoration: "none",
                  cursor: "pointer",
                  color: "#ffffff",
                  ".MuiTypography-root": {
                    fontFamily: "Roboto",
                    fontSize: "20px",
                    fontWeight: "500",
                    "&:hover": {
                      color: "#FFB800",
                    },
                  },
                }}
                primary={text}
              />
            </HashLink>
          </ListItemButton>
        ))}
      </List>
      <Box mt={2} display="flex" justifyContent="center">
        <StyledButton
          width="150px"
          href="https://www.lbank.com/en-US/trade/cr_usdt/"
          target="_blank"
          rel="noreferrer"
        >
          BUY NOW
        </StyledButton>
      </Box>
    </div>
  );
  return (
    <>
      <Box
        sx={{
          background: "transparent",
        }}
        width="100%"
        py={2}
      >
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <Box
                component={"img"}
                width={{ xs: "150px", sm: "190px" }}
                src={logo}
                alt=""
              />
            </Link>
            <Box
              display="flex"
              justifyContent={matches1 ? "end" : "space-between"}
              alignItems="center"
            >
              {!matches ? (
                <>
                  <Stack
                    direction="row"
                    gap={8}
                    sx={{
                      background: "#F5B325",
                      borderRadius: "12px",
                      boxShadow: "#D09B03 0px 5px 15px",
                      mr: 6,
                      py: 2,
                      px: 6,
                    }}
                  >
                    {navArray?.map(({ text, link }, i) => (
                      <HashLink
                        key={text}
                        smooth
                        to={link}
                        style={{ textDecoration: "none" }}
                      >
                        <StyledText>{text}</StyledText>
                      </HashLink>
                    ))}
                  </Stack>

                  <StyledButton
                    width="150px"
                    href="https://www.lbank.com/en-US/trade/cr_usdt/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    BUY NOW
                  </StyledButton>
                </>
              ) : (
                <>
                  {["left"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Button
                        onClick={toggleDrawer(anchor, true)}
                        style={{ zIndex: 1 }}
                      >
                        <MenuIcon
                          style={{
                            fontSize: "38px",
                            cursor: "pointer",
                            color: "#ffffff",
                          }}
                        />
                      </Button>
                      <Paper>
                        <SwipeableDrawer
                          classes={{ paper: classes.paper }}
                          anchor={anchor}
                          open={state[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                          onOpen={toggleDrawer(anchor, true)}
                        >
                          {list(anchor)}
                        </SwipeableDrawer>
                      </Paper>
                    </React.Fragment>
                  ))}
                </>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}

import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  CapitalRockBg,
  bittree,
  users,
  strategy,
  bit_coin,
  headOfficeBg,
} from "../components/SmallComponents/Images";
import presale from "../Images/presale.mp4";
import {
  StyledSubTitle,
  StyledTitle,
} from "../components/SmallComponents/AppComponents";

function CapitalRock() {
  const mobileMatches = useMediaQuery("(max-width:650px)");

  return (
    <>
      <Box
        id="about"
        component="section"
        sx={{
          background: `url('${CapitalRockBg}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          py: 8,
        }}
      >
        <Container>
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack
                data-aos={mobileMatches ? "fade-down" : "fade-right"}
                data-aos-delay="30"
                data-aos-duration="2000"
              >
                <Box
                  component="img"
                  sx={{ maxWidth: "100%" }}
                  src={bittree}
                  alt="bittree"
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                data-aos={mobileMatches ? "fade-up" : "fade-left"}
                data-aos-delay="30"
                data-aos-duration="2000"
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "#ffffff",
                    fontSize: "40px",
                    fontFamily: "Russo One",
                    mb: 1,
                  }}
                >
                  CAPITALROCK
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    color: "#F5B325",
                    fontSize: "36px",
                    fontFamily: "Russo One",
                    mb: 3,
                  }}
                >
                  What CapitalRock offers
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <Box
                    component="img"
                    sx={{ maxWidth: "100%", mr: 2.5 }}
                    src={users}
                    alt="users"
                  />
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontSize: "16px",
                      fontFamily: "Roboto",
                      mb: 2,
                    }}
                  >
                    The CapitalRock team has innovative plans to offer an
                    all-in-one experience that will allow holders to receive
                    CapitalRock Tokens and rewards while always keeping them up
                    to date on rising Token rates.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <Box
                    component="img"
                    sx={{ maxWidth: "100%", mr: 2.5 }}
                    src={strategy}
                    alt="strategy"
                  />
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontSize: "16px",
                      fontFamily: "Roboto",
                      mb: 2,
                    }}
                  >
                    <Box
                      component="span"
                      sx={{ fontSize: "18px", color: "#D09B03" }}
                    >
                      The strategy is simple:
                    </Box>{" "}
                    The more links you send to your family and friends, the
                    higher the chances for you have to earn Tokens and to
                    increase the value of them. Investors receive the
                    Capitalrock Token in return for their distribution
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <Box
                    component="img"
                    sx={{ maxWidth: "100%", mr: 2.5 }}
                    src={bit_coin}
                    alt="bit coin"
                  />
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontSize: "16px",
                      fontFamily: "Roboto",
                      mb: 2,
                    }}
                  >
                    In many ways, an ICO is the cryptocurrency version of an
                    Initial Public Offering (IPO) on the exchange.
                  </Typography>
                </Box>
                <Button
                  sx={{
                    mt: 2,
                    width: { xs: "120px", sm: "140px" },
                    fontSize: "16px",
                    fontWeight: "400",
                    boxShadow: "#D09B03 0px 5px 15px",
                    py: 1,
                    color: "#ffffff",
                    background: "#F5B325",
                    fontFamily: "Roboto",
                    borderRadius: "12px",
                    "&:hover": {
                      background: "#F5B325",
                    },
                  }}
                >
                  Read more
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box component="section">
        <Container>
          <Box
            sx={{
              backgroundImage: `url('${headOfficeBg}')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              py: { xs: 10, sm: 8 },
              px: { xs: 4, md: 8 },
            }}
          >
            <Grid container>
              <Grid item xs={12} md={6} px={2}>
                <Box
                  data-aos={mobileMatches ? "fade-down" : "fade-right"}
                  data-aos-delay="30"
                  data-aos-duration="2000"
                >
                  {/* <Typography
                    variant="h3"
                    sx={{
                      color: "#ffffff",
                      fontSize: "40px",
                      fontFamily: "Russo One",
                      mb: 1,
                    }}
                  >
                    CAPITALROCK
                  </Typography> */}
                  <StyledTitle colorStatus={true} mb={1}>
                    {" "}
                    CAPITALROCK
                  </StyledTitle>
                  {/* <Typography
                    variant="h3"
                    sx={{
                      color: "#F5B325",
                      fontSize: "36px",
                      fontFamily: "Russo One",
                      mb: 3,
                    }}
                  >
                    Head Office (Dubai)
                  </Typography> */}
                  <StyledTitle mb={3}> Head Office (Dubai)</StyledTitle>
                  <StyledSubTitle
                    mb={2}
                    // sx={{
                    //   color: "#ffffff",
                    //   fontSize: "16px",
                    //   fontFamily: "Roboto",
                    //   mb: 2,
                    // }}
                  >
                    CapitalRock, the innovative cryptocurrency, not only sets
                    itself apart in the digital realm but also in the physical
                    world, with its headquarters and a prominent physical office
                    situated in Europe. This strategic decision to establish a
                    tangible presence highlights the project's dedication to a
                    seamless fusion of modern technology and traditional
                    business practices. By anchoring itself in Europe,
                    CapitalRock demonstrates its commitment to fostering local
                    and international collaborations, while simultaneously
                    driving the evolution of financial landscapes.
                  </StyledSubTitle>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontSize: "16px",
                      fontFamily: "Roboto",
                      mb: 2,
                    }}
                  >
                    In the heart of Europe, CapitalRock thrives as a
                    groundbreaking cryptocurrency, redefining the intersection
                    of virtual and physical domains. With its headquarters and
                    key physical office strategically positioned, the currency
                    showcases a dynamic approach to the global financial sector.
                    As CapitalRock innovates from its European base, it propels
                    the narrative of cryptocurrencies forward, emphasizing the
                    importance of real-world connections in an increasingly
                    digital age.
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                px={2}
              >
                <Box
                  data-aos={mobileMatches ? "fade-up" : "fade-left"}
                  data-aos-delay="30"
                  data-aos-duration="2000"
                >
                  <Box
                    component="video"
                    muted
                    loop
                    autoPlay
                    sx={{ maxWidth: "100%", borderRadius: "20px" }}
                  >
                    <Box component="source" src={presale} type="video/mp4" />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default CapitalRock;
